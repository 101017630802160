import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, Row, Col } from "react-bootstrap";
import { RiPencilFill, RiDeleteBin6Fill } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { getalluser, userControl } from "./Services/ApiServices";
import ReactPaginate from "react-paginate";
import CommanTable from "./CommanTable";
import CommanPagination from "./CommanPagination";
import UserTable from "./CommanTable/UserTable";
import Loader from "./Loader/Loader";
import axios from "axios";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, Space, message } from "antd";

const UsersTable = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isReload, setIsReload] = useState(true);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [users, setUsers] = useState([]);
  const [ispagecount, isPageCount] = useState(false);
  const [isPerPage, setIsPerPage] = useState(10);
  const [iscount, setIsCount] = useState(null);
  const [ispagenum, setIsPageNum] = useState(1);
  const [isdata, setIsData] = useState([]);
  const [isheader, setIsHeaders] = useState([]);
  const [isshowing, setIsShowing] = useState([]);
  const [isResult, setIsResult] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [isinput, setIsInput] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
    status: "",
  });
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setSelectedUserId(null);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "",
      status: "",
    });
  };

  const handleShow = () => setShowModal(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddUser = () => {
    const newUser = { ...formData, id: users.length + 1 };
    setUsers([...users, newUser]);
    handleClose();
  };

  const handleEditUser = () => {
    const updatedUsers = users.map((user) =>
      user.id === selectedUserId ? { ...formData, id: selectedUserId } : user
    );
    setUsers(updatedUsers);
    handleClose();
  };

  const handleEditClick = (user) => {
    setFormData(user);
    setSelectedUserId(user.id);
    handleShow();
  };

  const handleDeleteUser = (userId) => {
    const updatedUsers = users.filter((user) => user.id !== userId);
    setUsers(updatedUsers);
  };
  const handleInputFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };
  const handlepagechange = (data) => {
    setIsPageNum(data.selected + 1);
  };
  const handleSwitchChange = (id) => {
    const status = id.row.isActive;
    Modal.confirm({
      title: "Confirmation",
      content: [
        `${
          status
            ? "Are you sure you want to inactive this user account? This action stops all the background jobs and login for this account "
            : "Are you sure you want to isactive this user account"
        }`,
      ],
      onOk() {
        switchHandler(id);
      },
    });
  };
  useEffect(() => {
    fetchData();
  }, [ispagenum, isPerPage]);
  const pageChangeHandler = (e) => {
    const value = e.target.value;
    setIsPerPage(parseInt(value));
  };

  const switchHandler = async (params) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === params.row.id ? { ...row, isActive: !row.isActive } : row
      )
    );
    try {
      const response = await userControl({
        email: params.row.email,
        isActive: !params.row.isActive,
      });
      if (response.status === 200) {
        message.success("Updated Successfully");
        fetchData();
      } else {
        console.error(`Toggle failed with status: ${response.status}`);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  // const fetchData = async (searchText) => {
  //   setIsLoading(true);
  //   // const response = await getalluser({
  //   //   pageNumber: ispagenum,
  //   //   pageSize: isPerPage,
  //   //   searchText: searchText || null,
  //   // });
  //   await getalluser({
  //     pageNumber: ispagenum,
  //     pageSize: isPerPage,
  //     searchText: searchText || null,
  //   })
  //     .then((response) => {
  //       toast("Profile updated successfully");
  //       setIsLoading(false);
  //       setIsData(response.data.result.data);
  //       setIsCount(response.data.result.total);
  //       setIsResult(response.data.result);
  //       setIsShowing({
  //         data: response.data.result.data.length,
  //         count: response.data.result.total,
  //       });
  //       if (!response.data || response.data.length === 0) {
  //         console.log("No data available");
  //       } else {
  //         const headers = response.data.result.headers;
  //         let dynamicColumns = headers.map((header) => {
  //           if (header.keyName === "status") {
  //             return {
  //               field: header.keyName,
  //               headerName: header.displayName,
  //               width: 130,
  //               renderCell: (params) => (
  //                 <div>
  //                   {params.row.status === "Active" ? (
  //                     <FontAwesomeIcon
  //                       icon={faCircle}
  //                       style={{ color: "green", width: "20px" }}
  //                     />
  //                   ) : (
  //                     <FontAwesomeIcon
  //                       icon={faCircle}
  //                       style={{ color: "red", width: "20px" }}
  //                     />
  //                   )}
  //                 </div>
  //               ),
  //             };
  //           } else if (header.keyName === "isActive") {
  //             return {
  //               field: header.keyName,
  //               headerName: header.displayName,
  //               width: 175,
  //               renderCell: (params) => (
  //                 <Form>
  //                   <Form.Check
  //                     type="switch"
  //                     id="custom-switch"
  //                     checked={params.row.isActive}
  //                     onChange={() => handleSwitchChange(params)}
  //                   />
  //                 </Form>
  //               ),
  //             };
  //           } else if (header.keyName === "marketPlaces") {
  //             return {
  //               field: header.keyName,
  //               headerName: header.displayName,
  //               width: 175,

  //               renderCell: (params) => (
  //                 <div
  //                   style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}
  //                 >
  //                   {" "}
  //                   {params.row.marketplaces.length > 0 ? (
  //                     <>
  //                       {params.row.marketplaces.map((marketPlace, index) => (
  //                         <div
  //                           key={index}
  //                           className="d-flex"
  //                           style={{ position: "relative" }}
  //                         >
  //                           <div style={{ width: "25px" }}>
  //                             {marketPlace.marketPlace === "US" ? (
  //                               <>
  //                                 <div className="d-flex align-items-center">
  //                                   <img
  //                                     src="https://cdn-icons-png.flaticon.com/512/323/323310.png"
  //                                     alt="US Flag"
  //                                     className="flag-icons"
  //                                   />
  //                                 </div>
  //                               </>
  //                             ) : marketPlace.marketPlace === "CA" ? (
  //                               <>
  //                                 <div className="d-flex align-items-center">
  //                                   <img
  //                                     src="https://cdn-icons-png.flaticon.com/512/197/197430.png"
  //                                     alt="US Flag"
  //                                     className="flag-icons"
  //                                   />
  //                                 </div>
  //                               </>
  //                             ) : marketPlace.marketPlace === "ES" ? (
  //                               <>
  //                                 <div className="d-flex align-items-center">
  //                                   <img
  //                                     src="https://cdn-icons-png.flaticon.com/512/197/197593.png"
  //                                     alt="US Flag"
  //                                     className="flag-icons"
  //                                   />
  //                                 </div>
  //                               </>
  //                             ) : marketPlace.marketPlace === "FR" ? (
  //                               <>
  //                                 <div className="d-flex align-items-center">
  //                                   <img
  //                                     src="https://cdn-icons-png.flaticon.com/512/197/197560.png"
  //                                     alt="US Flag"
  //                                     className="flag-icons"
  //                                   />
  //                                 </div>
  //                               </>
  //                             ) : marketPlace.marketPlace === "DE" ? (
  //                               <>
  //                                 <div className="d-flex align-items-center">
  //                                   <img
  //                                     src="https://cdn-icons-png.flaticon.com/512/197/197571.png"
  //                                     alt="US Flag"
  //                                     className="flag-icons"
  //                                   />
  //                                 </div>
  //                               </>
  //                             ) : marketPlace.marketPlace === "IT" ? (
  //                               <>
  //                                 <div className="d-flex align-items-center">
  //                                   <img
  //                                     src="https://cdn-icons-png.flaticon.com/512/323/323325.png"
  //                                     alt="US Flag"
  //                                     className="flag-icons"
  //                                   />
  //                                 </div>
  //                               </>
  //                             ) : marketPlace.marketPlace === "AU" ? (
  //                               <>
  //                                 <div className="d-flex align-items-center">
  //                                   <img
  //                                     src="https://cdn-icons-png.flaticon.com/512/323/323367.png"
  //                                     alt="US Flag"
  //                                     className="flag-icons"
  //                                   />
  //                                 </div>
  //                               </>
  //                             ) : marketPlace.marketPlace === "UK" ? (
  //                               <>
  //                                 <div className="d-flex align-items-center">
  //                                   <img
  //                                     src="https://cdn-icons-png.flaticon.com/512/197/197374.png"
  //                                     alt="US Flag"
  //                                     className="flag-icons"
  //                                   />
  //                                 </div>
  //                               </>
  //                             ) : null}
  //                           </div>
  //                           <div
  //                             style={{
  //                               position: "absolute",
  //                               right: "3px",
  //                               top: "-8px",
  //                             }}
  //                           >
  //                             {marketPlace.isActive ? (
  //                               <div className="active-icon"></div>
  //                             ) : (
  //                               <div className="active-icon-red"></div>
  //                             )}
  //                           </div>
  //                         </div>
  //                       ))}
  //                     </>
  //                   ) : (
  //                     <>{"No Marketplace"}</>
  //                   )}
  //                 </div>
  //               ),
  //             };
  //           } else if (header.keyName === "isConnectedAds") {
  //             return {
  //               field: header.keyName,
  //               headerName: header.displayName,
  //               width: 130,
  //               renderCell: (params) => (
  //                 <div>
  //                   {params.row.isConnectedAds ? (
  //                     <FontAwesomeIcon
  //                       icon={faCircleCheck}
  //                       style={{ color: "green", width: "20px" }}
  //                     />
  //                   ) : (
  //                     <FontAwesomeIcon
  //                       icon={faCircleXmark}
  //                       style={{ color: "red", width: "20px" }}
  //                     />
  //                   )}
  //                 </div>
  //               ),
  //             };
  //           } else if (header.keyName === "isConnectedKDP") {
  //             return {
  //               field: header.keyName,
  //               headerName: header.displayName,
  //               width: 130,
  //               renderCell: (params) => (
  //                 <div>
  //                   {params.row.isConnectedAds ? (
  //                     <FontAwesomeIcon
  //                       icon={faCircleCheck}
  //                       style={{ color: "green", width: "20px" }}
  //                     />
  //                   ) : (
  //                     <FontAwesomeIcon
  //                       icon={faCircleXmark}
  //                       style={{ color: "red", width: "20px" }}
  //                     />
  //                   )}
  //                 </div>
  //               ),
  //             };
  //             // } else if (header.keyName === "isActive") {
  //             //   return {
  //             //     field: header.keyName,
  //             //     headerName: header.displayName,
  //             //     width: 130,
  //             //     renderCell: (params) => (
  //             //       <></>
  //             //       // <div>
  //             //       //   {params.row.isConnectedAds ? (
  //             //       //     <FontAwesomeIcon
  //             //       //       icon={faCircleCheck}
  //             //       //       style={{ color: "green", width: "20px" }}
  //             //       //     />
  //             //       //   ) : (
  //             //       //     <FontAwesomeIcon
  //             //       //       icon={faCircleXmark}
  //             //       //       style={{ color: "red", width: "20px" }}
  //             //       //     />
  //             //       //   )}
  //             //       // </div>
  //             //     ),
  //             //   };
  //           } else {
  //             return {
  //               field: header.keyName,
  //               headerName: header.displayName,
  //               width: 175,
  //             };
  //           }
  //         });
  //         setColumns(dynamicColumns);
  //         const rowsList = response.data.result.data.map((row, index) => ({
  //           ...row,
  //           id: index + 1,
  //         }));
  //         setRows(rowsList);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };
  const fetchData = async (searchText) => {
    try {
      setIsLoading(true);
      const response = await getalluser({
        pageNumber: ispagenum,
        pageSize: isPerPage,
        searchText: searchText || null,
      });

      if (response && response.data && response.data.result) {
        toast("Data fetched successfully");
        setIsLoading(false);

        const responseData = response.data.result;
        setIsData(responseData.data);
        setIsCount(responseData.total);
        setIsResult(responseData);

        const headers = responseData.headers;
        const dynamicColumns = headers.map((header) => {
          switch (header.keyName) {
            case "status":
              return {
                field: header.keyName,
                headerName: header.displayName,
                width: 130,
                renderCell: (params) => (
                  <div>
                    <FontAwesomeIcon
                      icon={
                        params.row.status === "Active" ? faCircle : faCircle
                      }
                      style={{
                        color: params.row.status === "Active" ? "green" : "red",
                        width: "20px",
                      }}
                    />
                  </div>
                ),
              };
            case "isActive":
              return {
                field: header.keyName,
                headerName: header.displayName,
                width: 175,
                renderCell: (params) => (
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={params.row.isActive}
                      onChange={() => handleSwitchChange(params)}
                    />
                  </Form>
                ),
              };
            case "marketPlaces":
              return {
                field: header.keyName,
                headerName: header.displayName,
                width: 175,
                renderCell: (params) => (
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}
                  >
                    {" "}
                    {params.row.marketplaces.length > 0 ? (
                      <>
                        {params.row.marketplaces.map((marketPlace, index) => (
                          <div
                            key={index}
                            className="d-flex"
                            style={{ position: "relative" }}
                          >
                            <div style={{ width: "25px" }}>
                              {marketPlace.marketPlace === "US" ? (
                                <>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/512/323/323310.png"
                                      alt="US Flag"
                                      className="flag-icons"
                                    />
                                  </div>
                                </>
                              ) : marketPlace.marketPlace === "CA" ? (
                                <>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/512/197/197430.png"
                                      alt="US Flag"
                                      className="flag-icons"
                                    />
                                  </div>
                                </>
                              ) : marketPlace.marketPlace === "ES" ? (
                                <>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/512/197/197593.png"
                                      alt="US Flag"
                                      className="flag-icons"
                                    />
                                  </div>
                                </>
                              ) : marketPlace.marketPlace === "FR" ? (
                                <>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/512/197/197560.png"
                                      alt="US Flag"
                                      className="flag-icons"
                                    />
                                  </div>
                                </>
                              ) : marketPlace.marketPlace === "DE" ? (
                                <>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/512/197/197571.png"
                                      alt="US Flag"
                                      className="flag-icons"
                                    />
                                  </div>
                                </>
                              ) : marketPlace.marketPlace === "IT" ? (
                                <>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/512/323/323325.png"
                                      alt="US Flag"
                                      className="flag-icons"
                                    />
                                  </div>
                                </>
                              ) : marketPlace.marketPlace === "AU" ? (
                                <>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/512/323/323367.png"
                                      alt="US Flag"
                                      className="flag-icons"
                                    />
                                  </div>
                                </>
                              ) : marketPlace.marketPlace === "UK" ? (
                                <>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/512/197/197374.png"
                                      alt="US Flag"
                                      className="flag-icons"
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                right: "3px",
                                top: "-8px",
                              }}
                            >
                              {marketPlace.isActive ? (
                                <div className="active-icon"></div>
                              ) : (
                                <div className="active-icon-red"></div>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>{"No Marketplace"}</>
                    )}
                  </div>
                ),
              };
            case "isConnectedAds":
            case "isConnectedKDP":
              return {
                field: header.keyName,
                headerName: header.displayName,
                width: 130,
                renderCell: (params) => (
                  <div>
                    <FontAwesomeIcon
                      icon={
                        params.row.isConnectedAds
                          ? faCircleCheck
                          : faCircleXmark
                      }
                      style={{
                        color: params.row.isConnectedAds ? "green" : "red",
                        width: "20px",
                      }}
                    />
                  </div>
                ),
              };
            default:
              return {
                field: header.keyName,
                headerName: header.displayName,
                width: 175,
              };
          }
        });
        setColumns(dynamicColumns);

        const rowsList = responseData.data.map((row, index) => ({
          ...row,
          id: index + 1,
        }));
        setRows(rowsList);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const searchHandler = async () => {
    if (isinput) {
      fetchData(isinput);
      isPageCount(true);
    } else {
      alert("Empty");
    }
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     setSelectedFile(file);

  //     // Create a preview URL for the selected image
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setPreviewUrl(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  // const handleUpload = () => {
  //   // Perform API call here with selectedFile
  //   // Use FormData to handle file upload
  //   const access =
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjY0YmJjZDljMWIzMjRjYmUxYzE1MjlkOSIsInJvbGUiOiJVc2VyIiwianRpIjoiOTg1MDViMTUtNzA0ZS00Nzc4LWI0ZDgtNDE1MTg0N2VmNjc4IiwibmJmIjoxNzAwNDgwNzI2LCJleHAiOjE3MDA1MjM5MjYsImlhdCI6MTcwMDQ4MDcyNn0.XczjSHtQCbF6p_Jmu4C3iDHBRbhGa5lcyXbwjNx8mI4";
  //   const formData = new FormData();
  //   formData.append("file", selectedFile);

  //   // Make your API call using fetch or your preferred library
  //   fetch("https://api.aimosa.io/Users/ProfileImage", {
  //     method: "PUT",
  //     body: formData,
  //     headers: {
  //       Authorization: "Bearer " + access,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Handle the response from the API
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       // Handle errors
  //       console.error("Error uploading file:", error);
  //     });
  // };

  return (
    <Container fluid>
      <Row>
        <Col>
          {/* <div>
            {previewUrl ? (
              <img
                src={previewUrl}
                alt="Preview"
                style={{ width: "100px", height: "100px" }}
              />
            ) : (
              <img
                src="default-profile-picture.jpg" // Replace with the path to your default profile picture
                alt="Default Profile"
                style={{ width: "100px", height: "100px" }}
              />
            )}
            <br />
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>
          </div> */}
          <div className="d-flex align-items-center justify-content-between">
            <p className="tag-heading m-0">Users</p>
            <div className="search-bar">
              <input
                className="input-search"
                placeholder="Enter Keywords..."
                onChange={(e) => setIsInput(e.target.value)}
              />
              <Button className="search-btn" onClick={searchHandler}>
                <FontAwesomeIcon icon={faMagnifyingGlass} className="mx-2" />
                Search
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <p className="showing">{`Showing 1 – ${isshowing.data || 0} of ${
              isshowing.count || 0
            } results`}</p>
          </div>
          {/* <Button variant="primary" className="mb-3" onClick={handleShow}>
        <span>+</span> Add User
      </Button> */}
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedUserId ? "Edit User" : "Add User"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        onFocus={() => handleInputFocus("firstName")}
                        onBlur={handleInputBlur}
                        className={`MuiInputBase-input MuiOutlinedInput-input css-j6vbi8 ${
                          focusedInput === "firstName" ? "focused-input" : ""
                        }`}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        onFocus={() => handleInputFocus("lastName")}
                        onBlur={handleInputBlur}
                        className={`MuiInputBase-input MuiOutlinedInput-input css-j6vbi8 ${
                          focusedInput === "lastName" ? "focused-input" : ""
                        }`}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="email">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        onFocus={() => handleInputFocus("email")}
                        onBlur={handleInputBlur}
                        className={`MuiInputBase-input MuiOutlinedInput-input css-j6vbi8 ${
                          focusedInput === "email" ? "focused-input" : ""
                        }`}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        onFocus={() => handleInputFocus("password")}
                        onBlur={handleInputBlur}
                        className={`MuiInputBase-input MuiOutlinedInput-input css-j6vbi8 ${
                          focusedInput === "password" ? "focused-input" : ""
                        }`}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
              {selectedUserId !== null ? (
                <Button variant="primary" onClick={handleEditUser}>
                  Save Changes
                </Button>
              ) : (
                <Button variant="primary" onClick={handleAddUser}>
                  Add User
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          <div className="table-responsive">
            {isloading ? (
              <div className="loading">
                <Loader />
              </div>
            ) : (
              // <UserTable
              //   rows={rows}
              //   columns={columns}
              //   checkboxSelection={false}
              //   hideFooter={true}
              //   rowHeight={80}
              // />

              <div style={{ height: 477.2, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  checkboxSelection={false}
                  hideFooter={true}
                  rowHeight={80}
                />
              </div>
            )}
            {/* <Table>
          <thead>
            <tr>
              {isheader.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isdata.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.createdAt}</td>
                <td>{user.name}</td>
                <td>{user.id}</td>
                <td>{user.name}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-baseline">
              <div>
                <select
                  onChange={(e) => pageChangeHandler(e)}
                  className="drop-down-page"
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                </select>
              </div>
              <>
                <ReactPaginate
                  previousLabel={`previous`}
                  nextLabel={`next`}
                  breakLabel={`...`}
                  pageCount={isResult.lastPage}
                  // pageCount={isdata.length}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={6}
                  // forcePage={pageNumber === 0 ? 0 : pageNumber - 1}
                  onPageChange={handlepagechange}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  breakClassName={"page-item"}
                  activeClassName={"active"}
                  disabledClassName={"disabled"}
                />
              </>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UsersTable;
