import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Navigate } from "react-router-dom";
const LoginForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isCustomError, setIsCustomError] = useState(false);
  const [redirectToMain, setRedirectToMain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const predefinedEmail = "aimosallc@gmail.com";
  const predefinedPassword = "aimosa@123";
  useEffect(() => {
    setIsCustomError(false);
  }, [email, password]);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  // const responseGoogle = (response) => {
  //   console.log(response); // Handle the Google login response here
  // };

  // const responseFacebook = (response) => {
  //   console.log(response); // Handle the Facebook login response here
  // };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true

    if (email === predefinedEmail && password === predefinedPassword) {
      // Simulating an API call with setTimeout
      setTimeout(() => {
        setLoading(false); // Set loading to false after the API call

        // Login successful, set redirectToMain to true
        setRedirectToMain(true);
        console.log("Login successful");
      }, 2000);
    } else {
      setTimeout(() => {
        setLoading(false);

        console.log("Login failed");
        setIsCustomError(true);
      }, 1000);
    }
  };

  return (
    <Container fluid className="mt-5">
      <div className="brand-logo">
        {/* <img
          src="https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://datastorm.ai/&size=50"
          alt="logo"
          className="logosmalls"
        /> */}
        {/* <img
            src="/static/media/logo-white.a96fa35203df4d2957ec95142619307e.svg"
            alt="logo"
            className="logolarge"
          /> */}
        {/* <h4 className="logolarge">Data Storm</h4> */}
      </div>
      {redirectToMain && <Navigate to="/usertable" />}
      <div className="contanier-type1">
        <div className="text-center">
          <img
            src={
              "https://app.aimosa.io/static/media/logo.81aa9f2b0458b98657e159c29c2b1731.svg"
            }
            className="logo"
          />
        </div>
        {/* <div className="auth-login-icon google-login-icon"> */}
        {/* <div className="googleicon"> */}
        {/* <GoogleLogin
              clientId="YOUR_GOOGLE_CLIENT_ID" // Replace with your Google API client ID
              buttonText="Continue with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            /> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="auth-login-icon facebook-login-icon mb-4"> */}
        {/* <div className="facebookicon"> */}
        {/* <FacebookLogin
              appId="YOUR_FACEBOOK_APP_ID"
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              // Use the FaFacebook icon from react-icons/fa
            /> */}
        {/* <img src="https://www.logo.wine/a/logo/Facebook/Facebook-f_Logo-Blue-Logo.wine.svg" />
            ContinuewithFacebook
          </div> */}
        {/* </div> */}
        {isCustomError ? <span className="text-danger">Invalid</span> : null}
        <Form onSubmit={handleSubmit}>
          {/* <div className="separator mb-4">Or</div> */}

          <Form.Group className="mb-3">
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <div className="mb-0 password-cont">
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="show-password"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEyeSlash : faEye}
                />
              </span>
            </Form.Group>
          </div>
          <div className="mb-1">
            <p className="forgot-password text-right">
              {/* <a href="/forgot-password">Forgot Password?</a> */}
            </p>
          </div>
          <div className="mb-4">
            {/* <div className="custom-control custom-checkbox">
              <Form.Check
                type="checkbox"
                className="custom-control-input login-checkbox"
                id="customCheck1"
                label="Remember me"
              />
            </div> */}
          </div>
          <div className="d-grid">
            <Button variant="primary" type="submit">
              {!loading ? (
                "Log in"
              ) : (
                <div className="spinner-box">
                  <div className="pulse-container">
                    <div className="pulse-bubble pulse-bubble-1"></div>
                    <div className="pulse-bubble pulse-bubble-2"></div>
                    <div className="pulse-bubble pulse-bubble-3"></div>
                  </div>
                </div>
              )}
            </Button>
          </div>
          <div className="new-create-account">
            <p className="text-center">
              {/* No account? <a href="/signin">Sign Up</a> */}
            </p>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default LoginForm;
