import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import plan from "../../src/Assests/myaccount-billing-icon.png";
import dashboard from "../../src/Assests/dashboard.svg";
// import {FaqIcon} from "../images/FaqIcon.svg";

function Sidebar() {
  const [isdropdown, SetIsDropdown] = useState(false);
  const dropdownopen = () => {
    SetIsDropdown(!isdropdown);
  };
  return (
    <div id="sideMenubar" className="sidebar">
      <div className="header-wrapper">
        <div className="brand-logo">
          {/* <h4 className="logolarge">AI-MOSA</h4> */}
        </div>
      </div>
      <nav>
        <ul className="menus">
          <li className="menu-items">
            <Link to="/dashboard" style={{ cursor: "pointer" }}>
              <img src={dashboard} alt="drop icon" className="white-icon" />

              <span className="route-link-text" style={{ cursor: "pointer" }}>
                Dashboard
              </span>
            </Link>
          </li>
          <li className="menu-items">
            <Link to="/usertable">
              <img
                src="https://app.aimosa.io/static/media/bookshelf.fb2b1b8f4f52d09ed474.svg"
                alt="drop icon"
                className="white-icon"
              />
              <span className="route-link-text" style={{ cursor: "pointer" }}>
                User
              </span>
            </Link>
          </li>

          {/* <li className="menu-items">
            <Link to="/scheduler">
              <img
                src={require("../Assests/icons8-schedule-64.png")}
                alt="drop icon"
                className="white-icon"
              />
              <span className="route-link-text">Scheduler</span>
            </Link>
          </li> */}
          <li className="menu-items has-submenu">
            <Link onClick={dropdownopen} style={{ cursor: "pointer" }}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <img
                    src={require("../Assests/icons8-schedule-64.png")}
                    alt="drop icon"
                    className="white-icon"
                  />
                  <span style={{ color: "#82A49B" }}>Scheduler</span>
                </div>

                <FontAwesomeIcon
                  icon={faAngleDown}
                  color="#82A49B"
                  className="dropdown-icon"
                  onClick={dropdownopen}
                />
              </div>
            </Link>
            <ul
              className={`submenu-dropdown${
                isdropdown ? "submenu-dropdown-open" : ""
              }`}
            >
              <li className="menu-items" style={{ cursor: "pointer" }}>
                <Link to="/scheduler" style={{ cursor: "pointer" }}>
                  <span style={{ cursor: "pointer" }}>Ads Report</span>
                </Link>
              </li>
              <li className="menu-items">
                <Link to="/rules-automation" style={{ cursor: "pointer" }}>
                  <span style={{ cursor: "pointer" }}>Rules Automation</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="menu-items" style={{ cursor: "pointer" }}>
            <Link to="/faq">
              <img
                src={require("../Assests/quiz_FILL0_wght400_GRAD0_opsz24.0d90724b87f16a7729df.png")}
                alt="Faq Icon"
                style={{ width: "27px", height: "27px" }}
                className="white-icon"
              />
              <span className="route-link-text" style={{ cursor: "pointer" }}>
                FAQ
              </span>
            </Link>
          </li>
          <li className="menu-items" style={{ cursor: "pointer" }}>
            <Link to="/pricing">
              <img
                src={plan}
                alt="Faq Icon"
                style={{ width: "27px", height: "27px" }}
                className="white-icon"
              />
              <span className="route-link-text" style={{ cursor: "pointer" }}>
                Pricing
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
