import axios from "axios";
import React, { useEffect, useState } from "react";
import arrow from "../../src/Assests/arrow.svg";
import active from "../../src/Assests/acive-green-icon.svg";
import { Nav, Navbar, Container, NavDropdown, Form } from "react-bootstrap";

function Header() {
  const userName = "aimosa";

  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary page-top-bar d-flex justify-content-center"
    >
      <Container fluid>
        <Navbar.Toggle aria-controls="navbarScroll" />
        {/* <form className="header-search">
          <button type="submit">
            <i className="fa fa-search"></i>
          </button>
          <input
            type="text"
            placeholder="Search tutorial, post, community, etc..."
            name="search"
          />
        </form> */}
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>

          <Nav className="justify-content-end">
            <Nav.Link href="#action1" className="mx-4 custom-switch-container">
              {/* <Form>
                <Form.Check type="switch" id="custom-switch" />
              </Form> */}
            </Nav.Link>
            {/* <div className="vr"></div> */}
            {/* <Nav.Link href="#action2" className="ms-4">
              <img src="images\bellicon.svg" alt="img" />
            </Nav.Link>
            <Nav.Link href="#" className="mx-4">
              <img src="images\groupicon.svg" alt="img" />
            </Nav.Link> */}
            {/* <div className="vr"></div> */}
            <NavDropdown
              className="ms-4"
              title={
                <div className="profile-icon">
                  <div className="profile-img">
                    <img
                      src={require("../Assests/icon-128.png")}
                      alt="img"
                      width={50}
                      style={{ borderRadius: "50%" }}
                    />
                    <i>
                      {" "}
                      <img src={active} alt="img" />
                    </i>
                  </div>
                  {userName}
                  <span>
                    <img src={arrow} alt="dropicon" />
                  </span>
                </div>
              }
              id="navbarScrollingDropdown"
            >
              {/* <NavDropdown.Item href="/my-account/profile">
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item href="/my-account/billing">
                Billing & Plan
              </NavDropdown.Item>
              <NavDropdown.Item href="/my-account/notification">
                Notifications
              </NavDropdown.Item> */}
              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item href="/">Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
