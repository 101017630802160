import React from "react";
import AreaChartComponent from "./AreaChartComponent";
import StackedBar from "./StackedBar";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";

function Main() {
  return (
    <div className="mainpage">
      <div>
        <Sidebar />
        <div>
          <Header />
        </div>
      </div>
      <div className="outlet">
        <Outlet />
      </div>
    </div>
  );
}

export default Main;
