import React from "react";
import { Container } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StackedBarChart = () => {
  const options = {
    plugins: {
      title: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [100, 30, 50, 60, 70, 80, 40],
        backgroundColor: "#6C63FF",
        barThickness: 10,
      },
      {
        label: "Dataset 2",
        data: [100, 30, 50, 60, 70, 80, 40],
        backgroundColor: "#374151",
        barThickness: 10,
      },
    ],
  };

  return (
    <Container className="mt-3">
      <div className="gm">
        <Bar
          options={options}
          data={data}
          style={{ height: "700px",width:"700px"}} // Set desired width and height here
        />
      </div>
    </Container>
  );
};

export default StackedBarChart;
