import axios from "axios";
const baseUrl = "https://api.aimosa.io";
export const getalluser = ({ pageNumber, pageSize, searchText }) => {
  return axios.post(`${baseUrl}/Admin/Users`, {
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchText: searchText,
  });
};
export const getalluserscedule = ({ pageNumber, pageSize, searchText }) => {
  return axios.post(`${baseUrl}/Admin/Ads/Scheduler`, {
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchText: searchText,
  });
};
export const userControl = ({ email, isActive }) => {
  return axios.put(`${baseUrl}/Admin/Users`, {
    email: email,
    isActive: isActive,
  });
};
export const ruleAutomation = ({ pageNumber, pageSize, searchText }) => {
  return axios.post(`${baseUrl}/Admin/Rules/Automation/Scheduler`, {
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchText: searchText,
  });
};

export const ruleDetails = ({
  ruleId,
  scheduleAdsId,
  pageNumber,
  pageSize,
  searchText,
}) => {
  return axios.post(`${baseUrl}/Admin/Ruls/Automation/Action/Scheduler`, {
    ruleId: ruleId,
    scheduleAdsId: scheduleAdsId,
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchText: searchText,
  });
};

export const saveEditFAQ = ({ question, message, category, tag }) => {
  return axios.post(`${baseUrl}/FAQ`, {
    question: question,
    message: message,
    category: category,
    tag: tag,
  });
};

export const deleteFAQ = ({ id }) => {
  return axios.delete(`${baseUrl}/FAQ/${id}`);
};

export const updateFAQ = ({ id, question, message, category, tag }) => {
  return axios.put(`${baseUrl}/FAQ/${id}`, {
    id: id,
    question: question,
    message: message,
    category: category,
    tag: tag,
  });
};
export const getPlan = () => {
  return axios.get(`${baseUrl}/Plan`);
};
export const getByIdPlan = (id) => {
  return axios.get(`${baseUrl}/Plan/${id}`);
};
export const postPlan = (request) => {
  return axios.post(`${baseUrl}/Plan`, request);
};
export const updatedPlan = (request) => {
  return axios.put(`${baseUrl}/Plan/${request.id}`, request);
};
