import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import LoginForm from "./Components/LoginForm";
import SigninForm from "./Components/SigninForm";
import Navsidebar from "./Components/Navsidebar";
import StackedBar from "./Components/StackedBar";
import SpendChart from "./Components/SpendChart";
import AreaChartComponent from "./Components/AreaChartComponent";
import Main from "./Components/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import Header from "./Components/Header";
import UsersTable from "./Components/MyForm";
import Scheduler from "./Components/Scheduler";
import Dashboard from "./Components/Dashboard";
import Rules from "./Components/Rules";
import RuleDetails from "./Components/RuleDetails";
import Faq from "./Components/Faq/Faq";
import Pricing from "./Components/Pricing";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/signin" element={<SigninForm />} />
          <Route element={<Main />}>
            <Route path="/usertable" element={<UsersTable />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/scheduler" element={<Scheduler />} />
            <Route path="/rules-automation" element={<Rules />} />
            <Route path="/rule-details/:id/:key" element={<RuleDetails />} />
            <Route path="/faq" element={<Faq />} />
            {/* <Route path="/pricing" element={<Pricing />} /> */}
          </Route>

          {/* <Route
            path="/main"
            element={
              <>
                <Main />
              </>
            }
          /> */}
          {/* <Route path="/usertable" element={<UsersTable />} />  */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
