import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import CommanTable from "./CommanTable";
import { ruleDetails } from "./Services/ApiServices";
import CommanPagination from "./CommanPagination";
import SchedulerTable from "./CommanTable/SchedulerTable";
import ReactPaginate from "react-paginate";
import Loader from "./Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
function RuleDetails() {
  const [isloading, setIsLoading] = useState(false);
  const [isdata, setIsData] = useState([]);
  const [isheader, setIsHeaders] = useState([]);
  const [iscount, setIsCount] = useState([]);
  const [isshowing, setIsShowing] = useState([]);
  const [ispagenum, setIsPageNum] = useState(1);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [isinput, setIsInput] = useState("");
  const { ruleId, scheduleAdsId } = useParams();
  const [isPerPage, setIsPerPage] = useState(10);
  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [ispagenum, isPerPage]);
  const handlepagechange = (data) => {
    setIsPageNum(data.selected + 1);
  };
  const params = useParams();
  const pageChangeHandler = (e) => {
    const value = e.target.value;
    setIsPerPage(parseInt(value));
  };
  const fetchData = async (searchText) => {
    try {
      const responceData = await ruleDetails({
        ruleId: params.id,
        scheduleAdsId: params.key,
        pageNumber: ispagenum,
        pageSize: isPerPage,
        searchText: searchText || "",
      });
      setIsShowing({
        data: responceData.data.result.data.length,
        count: responceData.data.result.total,
        lastPage: responceData.data.result.lastPage,
      });
      const headers = responceData.data.result.headers;
      if (columns.length < 1) {
        let dynamicColumns = headers.map((header) => {
          if (header.keyName === "marketplace") {
            return {
              field: header.keyName,
              headerName: header.displayName,
              width: 155,
              renderCell: (params) => (
                <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                  {params.row.marketplace.length > 0 ? (
                    <>
                      {params.row.marketplace.map((marketPlace, index) => (
                        <div key={index} className="d-flex">
                          <div style={{ width: "25px" }}>
                            {marketPlace === "US" ? (
                              <>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/323/323310.png"
                                    alt="US Flag"
                                    className="flag-icons"
                                  />
                                </div>
                              </>
                            ) : marketPlace === "CA" ? (
                              <>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/197/197430.png"
                                    alt="US Flag"
                                    className="flag-icons"
                                  />
                                </div>
                              </>
                            ) : marketPlace === "ES" ? (
                              <>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/197/197593.png"
                                    alt="US Flag"
                                    className="flag-icons"
                                  />
                                </div>
                              </>
                            ) : marketPlace === "FR" ? (
                              <>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/197/197560.png"
                                    alt="US Flag"
                                    className="flag-icons"
                                  />
                                </div>
                              </>
                            ) : marketPlace === "DE" ? (
                              <>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/197/197571.png"
                                    alt="US Flag"
                                    className="flag-icons"
                                  />
                                </div>
                              </>
                            ) : marketPlace === "IT" ? (
                              <>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/323/323325.png"
                                    alt="US Flag"
                                    className="flag-icons"
                                  />
                                </div>
                              </>
                            ) : marketPlace === "AU" ? (
                              <>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/323/323367.png"
                                    alt="US Flag"
                                    className="flag-icons"
                                  />
                                </div>
                              </>
                            ) : marketPlace === "UK" ? (
                              <>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/197/197374.png"
                                    alt="US Flag"
                                    className="flag-icons"
                                  />
                                </div>
                              </>
                            ) : (
                              marketPlace
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              ),
            };
          } else if (header.keyName === "duration") {
            return {
              field: header.keyName,
              headerName: header.displayName,
              width: 300,
              renderCell: (params) => <div>{params.row.duration.value}</div>,
            };
          }
          return {
            field: header.keyName,
            headerName: header.displayName,
            width: 175,
          };
        });
        setColumns(dynamicColumns);
      }
      const rowsList = responceData.data.result.data.map((row, index) => ({
        ...row,
        id: index + 1,
      }));

      setRows(rowsList);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const searchHandler = async () => {
    if (isinput) {
      await fetchData(isinput);
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-between">
            <p className="tag-heading m-0">
              <Link
                to="/rules-automation"
                style={{ color: "#00976d", fontWeight: "600" }}
              >
                Rules Automation
              </Link>{" "}
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{ marginLeft: "5px" }}
              />{" "}
              Actions
            </p>
            <div className="search-bar">
              <input
                className="input-search"
                placeholder="Enter Keywords..."
                onChange={(e) => setIsInput(e.target.value)}
              />
              <Button className="search-btn" onClick={searchHandler}>
                <FontAwesomeIcon icon={faMagnifyingGlass} className="mx-2" />
                Search
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <p className="showing">{`Showing 1 – ${isshowing.data || 0} of ${
              isshowing.count || 0
            } results`}</p>
          </div>
          <div className="table-responsive">
            {isloading ? (
              <div className="loading">
                <Loader />
              </div>
            ) : (
              // <SchedulerTable data={isdata} headers={isheader} />
              <div style={{ height: 477.2, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  checkboxSelection={false}
                  hideFooter={true}
                  rowHeight={70}
                />
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-baseline">
            <div>
              <select
                onChange={(e) => pageChangeHandler(e)}
                className="drop-down-page"
              >
                <option>10</option>
                <option>20</option>
                <option>30</option>
              </select>
            </div>
            <div>
              <ReactPaginate
                previousLabel={`previous`}
                nextLabel={`next`}
                breakLabel={`...`}
                pageCount={isshowing.lastPage}
                marginPagesDisplayed={3}
                pageRangeDisplayed={6}
                // forcePage={pageNumber === 0 ? 0 : pageNumber - 1}
                onPageChange={handlepagechange}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                nextClassName={"page-item"}
                breakClassName={"page-item"}
                activeClassName={"active"}
                disabledClassName={"disabled"}
              />
            </div>
          </div>{" "}
        </Col>
      </Row>
    </Container>
  );
}

export default RuleDetails;
