// faq.js
import React, { useState, useEffect } from "react";
import { Col, Row, Button, Container, Modal } from "react-bootstrap";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import { deleteFAQ, saveEditFAQ, updateFAQ } from "../Services/ApiServices";
import { GridDeleteIcon } from "@mui/x-data-grid";
import FaqSideTab from "./FaqSideTab";
import "./faq.css";

const Faq = () => {
  const [faqData, setFaqData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [searchFormActive, setSearchFormActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [accordionState, setAccordionState] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [editedAnswer, setEditedAnswer] = useState("");
  const [editedCategory, setEditedCategory] = useState("");
  const [editedTag, setEditedTag] = useState("");
  const [editedId, setEditedId] = useState("");
  const [saveButtonText, setSaveButtonText] = useState("Save");

  useEffect(() => {
    fetchFaqData();
  }, []);

  const fetchFaqData = async (searchText = "", page = 1) => {
    const pageSize = 10;
    let apiUrl = `https://api.aimosa.io/FAQ?pageNumber=${page}&pageSize=${pageSize}`;

    if (searchText) {
      apiUrl += `&Filters.searchText=${searchText}`;
    } else if (selectedCategory !== null) {
      apiUrl += `&Filters.Category=${encodeURIComponent(selectedCategory)}`;
    }

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      const faqData = data.result.data || [];
      setFaqData(faqData);
      setInitialFetchComplete(true);
      setCurrentPage(data.result.currPage || 1);
      setTotalPages(data.result.lastPage || 1);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  };

  const handleSearch = (searchText) => {
    setSearchFormActive(true);
    setCurrentPage(1);
    fetchFaqData(searchText);
  };

  const handlePageChange = (event, page) => {
    const searchText = searchFormActive
      ? document.getElementById("globalSearch")?.value
      : undefined;

    setCurrentPage(page);
    fetchFaqData(searchText, page);
  };

  const handleAccordionToggle = (itemId) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
      editMode: null,
    }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditedQuestion("");
    setEditedAnswer("");
    setEditedCategory("");
    setEditedTag("");
    setSaveButtonText("Save");
    setEditMode(false);
  };

  const handleEditClick = (id, question, answer, category, tag) => {
    setEditedId(id);
    setEditedQuestion(question);
    setEditedAnswer(answer);
    setEditedCategory(category);
    setEditedTag(tag);
    setSaveButtonText("Update");
    setEditMode(true);
    openModal();
  };

  const handleSaveClick = (id) => {
    setEditMode(null);
  };

  const handleDeleteClick = (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this question?"
    );
    if (confirmed) {
      deleteFAQ({ id, isDelete: true })
        .then(() => fetchFaqData())
        .catch((error) => console.error("Error deleting FAQ:", error));
    }
  };

  const handleSave = () => {
    const questionInput = document.getElementById("question");
    const messageInput = document.getElementById("message");
    const categoryInput = document.getElementById("category");
    const tagInput = document.getElementById("tag");

    const question = questionInput.value;
    const message = messageInput.value;
    const category = categoryInput.value;
    const tag = tagInput.value;

    if (question.trim() === "") {
      alert("Question is required");
      return;
    }

    const updatedFaqData = faqData.map((item) =>
      item.id === editMode
        ? { ...item, question, message, category, tag }
        : item
    );

    saveEditFAQ({ question, message, category, tag })
      .then(() => {
        fetchFaqData();
        closeModal();
        setEditedQuestion("");
        setEditedAnswer("");
        setEditedCategory("");
        setEditedTag("");
      })
      .catch((error) => console.error("Error saving FAQ:", error));
  };

  const handleEdit = () => {
    const questionInput = document.getElementById("question");
    const messageInput = document.getElementById("message");
    const categoryInput = document.getElementById("category");
    const tagInput = document.getElementById("tag");
    const Id = editedId;

    const question = questionInput.value;
    const message = messageInput.value;
    const category = categoryInput.value;
    const tag = tagInput.value;
    const id = Id;

    if (question.trim() === "") {
      alert("Question is required");
      return;
    }

    updateFAQ({ id, question, message, category, tag })
      .then(() => {
        fetchFaqData();
        closeModal();
        setEditedQuestion("");
        setEditedAnswer("");
        setEditedCategory("");
        setEditedTag("");
      })
      .catch((error) => console.error("Error saving FAQ:", error));
  };

  const renderSearchForm = () => (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="search-filter-container">
        <div className="search-icon">
          <i className="fa fa-search"></i>
        </div>
        <input
          type="text"
          placeholder="Search a question..."
          id="globalSearch"
          name="globalSearch"
          className="faq-header-input"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: "100%", paddingLeft: "40px" }}
        />
      </div>
    </form>
  );

  const renderHeader = () => (
    <div className="main-cont-header bookself-container">
      <Row className="page-header">
        <Col>
          <div className="main-con-page-title-container">
            <div className="title">
              <h3 className="page-title">FAQ</h3>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  const renderFaqHeader = () => (
    <div className="faq-header-bg">
      <Row className="justify-content-center">
        <Col md={5}>
          <div className="faq-header-title">Hello, how can we help?</div>
          <div>{renderSearchForm()}</div>
          <p>or select a category to quickly find the help you require.</p>
          {/* <div>{addNewButton()}</div> */}
        </Col>
      </Row>
    </div>
  );

  // const addNewButton = () => (
  //   <Row>
  //     <Col>
  //       <div className="d-flex justify-content-end">
  //         <button className="custom-button" >
  //           Add New
  //         </button>
  //       </div>
  //     </Col>
  //   </Row>
  // );

  const renderFaqQaHeader = () => {
    const categoryIcons = {};
    const icon =
      selectedCategory !== null ? categoryIcons[selectedCategory] : undefined;

    return selectedCategory !== null && !searchFormActive ? (
      <div className="faq-qa-header-container">
        <>
          <div className="faq-qa-header">
            <div className="faq-qa-header-image">
              <img src={icon} alt={`Category: ${selectedCategory}`} />
            </div>
          </div>
          <div className="faq-qa-header">
            <h2>{selectedCategory}</h2>
            <p>Get help with {selectedCategory}</p>
          </div>
          <div>
            <button>Add new</button>
          </div>
        </>
      </div>
    ) : null;
  };

  const renderFaqQaCard = () => (
    <div className="row faq-qa-card-container">
      {faqData.map((item) => (
        <Col md={12} xs={12} key={item.id}>
          <Accordion
            expanded={accordionState[item.id]}
            onChange={() => handleAccordionToggle(item.id)}
            elevation={0}
            square
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {!editMode || editMode !== item.id ? (
                <>
                  <Typography variant="h6" className="custom-question">
                    {item.question}
                  </Typography>
                  {accordionState[item.id] && (
                    <>
                      <IconButton
                        className="edit-icon"
                        style={{
                          marginLeft: "auto",
                          color: "#00976d",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditClick(
                            item.id,
                            item.question,
                            item.message,
                            item.category,
                            item.tag
                          );
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        className="delete-icon"
                        style={{
                          marginLeft: "10px",
                          color: "#ff0000",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(item.id);
                        }}
                      >
                        <GridDeleteIcon />
                      </IconButton>
                    </>
                  )}
                </>
              ) : (
                <div
                  contentEditable
                  dangerouslySetInnerHTML={{ __html: editedQuestion }}
                  onBlur={() => handleSaveClick(item.id)}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              {!editMode || editMode !== item.id ? (
                <div>{item.message}</div>
              ) : (
                <div
                  contentEditable
                  dangerouslySetInnerHTML={{ __html: editedAnswer }}
                  onBlur={() => handleSaveClick(item.id)}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Col>
      ))}
      <hr />
      <div className="custom-table-footer">
        <Row>
          <Col md={5}></Col>
          <Col md={6} xs={12}>
            <div className="table-footer-right">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <Container fluid>
      {renderHeader()}
      <div className="main-content-container faq-container">
        <hr />
        <div className="container-fluid">
          {initialFetchComplete && renderFaqHeader()}
        </div>
        <div className="container-fluid padding-lr-30 name-btn">
          <div className="d-flex justify-content-end mb-2">
            <Button onClick={openModal} className="custom-button">
              Add-New
            </Button>
          </div>
          <Row>
            <Col md={3}>
              <FaqSideTab setFaqData={setFaqData} />
              <Modal show={isModalOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {editMode ? "Update FAQ Information" : "Add New FAQ"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col>
                        <div>
                          <label htmlFor="nameInput">Question:</label>
                          <textarea
                            type="text"
                            id="question"
                            class="message-box"
                            placeholder="Enter Question"
                            value={editedQuestion}
                            onChange={(e) => setEditedQuestion(e.target.value)}
                            required
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <label htmlFor="questionInput">Answer:</label>
                          <textarea
                            id="message"
                            class="message-box"
                            placeholder="Enter Answer"
                            value={editedAnswer}
                            onChange={(e) => setEditedAnswer(e.target.value)}
                          ></textarea>
                        </div>
                        <div></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <label htmlFor="answerInput">Category:</label>
                          <select
                            id="category"
                            className="category-box"
                            value={editedCategory}
                            onChange={(e) => setEditedCategory(e.target.value)}
                          >
                            <option value="">Select a category</option>
                            <option value="Products & Services">
                              Products & Services
                            </option>
                            <option value="Get Started">Get Started</option>
                            <option value="Subscription & Payment">
                              Subscription & Payment
                            </option>
                            <option value="Data Security">Data Security</option>
                            <option value="Troubleshooting">
                              Troubleshooting
                            </option>
                            {/* Add more options as needed */}
                          </select>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div>
                          <label htmlFor="answerInput">Tag:</label>
                          <input
                            type="text"
                            id="tag"
                            placeholder="Tag"
                            value={editedTag}
                            onChange={(e) => setEditedTag(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="secondary"
                    variant="secondary"
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="secondary"
                    variant="secondary"
                    onClick={
                      saveButtonText === "Update" ? handleEdit : handleSave
                    }
                    id="saveButton"
                  >
                    {saveButtonText}
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
            <Col md={9}>
              <div className="container faq-qa-card">
                <Col md={12}>
                  {initialFetchComplete && renderFaqQaHeader()}
                  {renderFaqQaCard()}
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Faq;
