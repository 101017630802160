import React, { useState } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function PasswordForm() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    // Extract form data
    const formData = new FormData(event.target);
    const password = formData.get("password");
    const confirmPassword = formData.get("confirmPassword");

    // Simulate API call
    setTimeout(() => {
      setLoading(false);

      // Implement your logic for form submission
      console.log("Password:", password);
      console.log("Confirm Password:", confirmPassword);

      // Reset the form or navigate after submission
      setPassword("");
      setConfirmPassword("");
    }, 2000);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <Container className="mt-5">
      <div className="brand-logo">
        {/* Include your logo here */}
        <h4 className="logolarge">Data Storm</h4>
      </div>
      <div className="contanier-type1">
        <Form onSubmit={handleSubmit}>
          <div className="mb-3 password-cont">
            <Form.Control
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
            <span className="show-password" onClick={togglePasswordVisibility}>
              <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
            </span>
          </div>
          <div className="mb-3">
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </div>
          <div className="d-grid">
            <Button variant="primary" type="submit">
              {!loading ? (
                "Create Account"
              ) : (
                <div className="spinner-box">
                  <div className="pulse-container">
                    <div className="pulse-bubble pulse-bubble-1"></div>
                    <div className="pulse-bubble pulse-bubble-2"></div>
                    <div className="pulse-bubble pulse-bubble-3"></div>
                  </div>
                </div>
              )}
            </Button>
          </div>
          <div className="new-create-account">
            <p className="text-center">
              Have an account? <a href="/">Sign Up</a>
            </p>
          </div>
        </Form>
      </div>
    </Container>
  );
}

export default PasswordForm;
